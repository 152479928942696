import axios from 'axios';
import global from '../../components/Global';

let base = global.serverBase;

export const queryStationInfo = params => { return axios.post(base + '/stationInfoManage/queryStationInfo', params)};

export const updateStationInfo = params => { return axios.post(base + '/stationInfoManage/updateStationInfo', params)};

export const createStationInfo = params => { return axios.post(base + '/stationInfoManage/createStationInfo', params)};

export const deleteStationInfo = params => {

    return axios.post(base + '/stationInfoManage/deleteStationInfo', params)
};

export const queryStationInfoByParam = params => { return axios.post(base + '/stationInfoManage/queryStationInfoByParam', params)};

export const queryOrgInfo = params => { return axios.post(base + '/stationInfoManage/queryOrgInfo', params)};

export const queryAllAreaInfo = params => { return axios.post(base + '/stationInfoManage/queryAllAreaInfo', params)};

export const queryStationAssignUserList = params => { return axios.post(base + '/stationInfoManage/queryStationAssignUserList', params)};

export const stationAssignUser = params => { return axios.post(base + '/stationInfoManage/stationAssignUser', params)};

export const stationCancelAssignUser = params => { return axios.post(base + '/stationInfoManage/stationCancelAssignUser', params)};