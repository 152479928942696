<template>
    <div>
        <el-dialog :title="addOrEditDeviceDialogTitle" :visible.sync="addOrEditDeviceFormVisible" width="1100px" center
                   v-if="addOrEditDeviceFormVisible" top="0" center :close-on-click-modal="false">
            <el-steps :active="active" :align-center="true">
                <el-step @click.native="stepChange(0)" :status="active0Status" title="基础信息"></el-step>
                <el-step @click.native="stepChange(1)" :status="active1Status" title="发电机"></el-step>
                <el-step @click.native="stepChange(2)" :status="active2Status" title="电池包"></el-step>
                <el-step @click.native="stepChange(3)" :status="active3Status" title="电源变换器"></el-step>
                <el-step @click.native="stepChange(4)" :status="active4Status" title="环境"></el-step>
            </el-steps>
            <div style="width: 100%;height: 520px">
                <div v-show="active == 0" style="padding-top: 50px" key="base">
                    <el-form :model="deviceForm" :rules="deviceFormRules" ref="deviceForm" label-width="100px"
                             :inline="true">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="归属机构" prop="orgId">
                                    <el-select v-model="deviceForm.orgId" placeholder="请选择" style="width:200px"
                                               @change="orgSelectChange">
                                        <el-option v-for="item in orgOption" :key="item.id" :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="归属基站" prop="stationId" label-width="150px">
                                    <el-select v-model="deviceForm.stationId" placeholder="请选择" style="width:200px">
                                        <el-option v-for="item in stationOption" :key="item.id" :label="item.name"
                                                   :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="设备编号" prop="deviceNum">
                                    <el-input v-bind:disabled="addOrEditNumberStyle" id="addOrEditNumber"
                                              v-model="deviceForm.deviceNum" placeholder="请输入" autocomplete="off"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="是否推送钉钉告警" prop="deviceStatus" label-width="150px">
                                    <el-radio-group v-model="deviceForm.deviceStatus" size="small">
                                        <el-radio-button label="0">推送</el-radio-button>
                                        <el-radio-button label="1">不推送</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="负载1" prop="load1">
                                    <el-input v-model="deviceForm.load1" placeholder="请输入" autocomplete="off"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="负载2" prop="load2">
                                    <el-input v-model="deviceForm.load2" placeholder="请输入" autocomplete="off"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="负载3" prop="load3">
                                    <el-input v-model="deviceForm.load3" placeholder="请输入" autocomplete="off"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div v-show="active == 1" style="padding-top: 50px;" key="engine">
                    <el-table size="mini" :data="engineTableData" max-height="490">
                        <el-table-column label="类型" prop="majorClassName" align="center"></el-table-column>
                        <el-table-column label="设备编号" align="center" prop="number">
                            <template slot-scope="scope">
                                <el-input size="small" v-model="scope.row.number"
                                          placeholder="若不填系统自动生成"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="center">
                            <template slot="header">
                                <span style="color: red; padding-right: 2px">*</span>
                                <span>组件规格(L)</span>
                            </template>
                            <template slot-scope="scope">
                                <div v-if="scope.row.subUnit != null">
                                    <el-input size="small" v-model="scope.row.subUnit"></el-input>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-show="active == 2" style="padding-top: 30px" key="bat">
                    <el-button type="primary" icon="el-icon-plus" size="mini" @click="addBatteryRow()">新增</el-button>
                    <el-form :model="batForm" ref="batForm" :inline="true" :rules="batFormRules" :inline-message="true">
                        <el-table size="mini" :data="batForm.batteryTableData" max-height="490">
                            <el-table-column label="类型" align="center">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'batteryTableData.'+ scope.$index +'.majorClassName'"
                                                  style="margin-bottom: 0px;">
                                        <span>{{ scope.row.majorClassName }}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="设备编号" align="center">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'batteryTableData.'+ scope.$index +'.number'"
                                                  style="margin-bottom: 0px;">
                                        <el-input size="small" v-model="scope.row.number"
                                                  placeholder="若不填系统自动生成"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column width="200" align="center">
                                <template slot="header">
                                    <span style="color: red; padding-right: 2px">*</span>
                                    <span>组号</span>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'batteryTableData.'+ scope.$index +'.serialNumber'"
                                                  :rules="batFormRules.serialNumber" style="margin-bottom: 0px;">
                                        <el-input size="small" v-model="scope.row.serialNumber" style="width: 80px"
                                                  oninput="value=value.replace(/[^\d]/g, '')"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <el-form-item style="margin-bottom: 0px;">
                                        <el-button size="small" type="text"
                                                   @click.native.prevent="deleteBatteryRow(scope.$index, batForm.batteryTableData)">
                                            删除
                                        </el-button>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </div>
                <div v-show="active == 3" style="padding-top: 30px" key="dc">
                    <el-form :model="dcForm" ref="dcForm" :inline="true" :rules="dcFormRules" :inline-message="true">
                        <el-button type="primary" icon="el-icon-plus" size="mini"
                                   @click="addDcRow('光伏模块', dcDeviceTypeId1)">新增光伏模块
                        </el-button>
                        <el-table size="mini" :data="dcForm.tableData1" max-height="190"
                                  :row-class-name="rowClassName1">
                            <el-table-column label="类型" width="200" align="center">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData1.'+ scope.$index +'.majorClassName'"
                                                  style="margin-bottom: 0px;">
                                        <span>{{ scope.row.majorClassName }}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="设备编号" width="200" align="center">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData1.'+ scope.$index +'.number'"
                                                  style="margin-bottom: 0px;">
                                        <el-input size="small" v-model="scope.row.number"
                                                  placeholder="若不填系统自动生成"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="组号" type="index" width="50" align="center">
                                <template slot-scope="scope">
                                    {{ scope.$index + 1 }}
                                </template>
                            </el-table-column>
                            <el-table-column width="200" align="center">
                                <template slot="header">
                                    <span style="color: red; padding-right: 2px">*</span>
                                    <span>组件数量</span>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData1.'+ scope.$index +'.subCount'"
                                                  :rules="dcFormRules.subCount" style="margin-bottom: 0px;">
                                        <el-input size="small" v-model="scope.row.subCount"
                                                  style="width: 120px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column width="200" align="center">
                                <template slot="header">
                                    <span style="color: red; padding-right: 2px">*</span>
                                    <span>组件规格(W)</span>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData1.'+ scope.$index +'.subUnit'"
                                                  :rules="dcFormRules.subUnit" style="margin-bottom: 0px;">
                                        <el-input size="small" v-model="scope.row.subUnit"
                                                  style="width: 120px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-form-item style="margin-bottom: 0px;">
                                        <el-button size="small" type="text"
                                                   @click.native.prevent="deleteDcRow(scope.$index, '光伏模块')">删除
                                        </el-button>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button type="primary" icon="el-icon-plus" size="mini"
                                   @click="addDcRow('整流模块', dcDeviceTypeId2)" style="margin: 20px 0px 10px 0px">
                            新增整流模块
                        </el-button>
                        <el-table size="mini" :data="dcForm.tableData2" max-height="190"
                                  :row-class-name="rowClassName2">
                            <el-table-column label="类型" width="200" align="center">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData2.'+ scope.$index +'.majorClassName'"
                                                  style="margin-bottom: 0px;">
                                        <span>{{ scope.row.majorClassName }}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="设备编号" width="200" align="center">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData2.'+ scope.$index +'.number'"
                                                  style="margin-bottom: 0px;">
                                        <el-input size="small" v-model="scope.row.number"
                                                  placeholder="若不填系统自动生成"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="组号" type="index" width="50" align="center">
                                <template slot-scope="scope">
                                    {{ dcForm.tableData1.length + scope.$index + 1 }}
                                </template>
                            </el-table-column>
                            <el-table-column width="400">
                            </el-table-column>
                            <el-table-column label="操作" width="200">
                                <template slot-scope="scope">
                                    <el-form-item style="margin-bottom: 0px;">
                                        <el-button size="small" type="text"
                                                   @click.native.prevent="deleteDcRow(scope.$index, '整流模块')">删除
                                        </el-button>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </div>
                <div v-show="active == 4" style="padding-top: 50px;" key="environment">
                    <el-form :inline="true" :inline-message="true">
                        <el-table size="mini" :data="environmentTableData">
                            <el-table-column label="类型" prop="majorClassName" width="300"
                                             align="center"></el-table-column>
                            <el-table-column label="设备编号" width="300" align="center" prop="number">
                                <template slot-scope="scope">
                                    <el-form-item style="margin: auto">
                                        <el-input size="small" v-model="scope.row.number"
                                                  placeholder="若不填系统自动生成" style="width: 200px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>

                    <el-form :model="qxForm" ref="qxForm" :inline="true" :rules="qxFormRules" :inline-message="true"
                             style="margin-top: 20px;display: none">
                        <el-button type="primary" icon="el-icon-plus" size="mini"
                                   @click="addQxRow">气象仪
                        </el-button>
                        <el-table size="mini" :data="qxForm.tableData" :row-class-name="rowClassName1">
                            <el-table-column label="类型" align="center" width="300">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.'+ scope.$index +'.majorClassName'"
                                                  style="margin: auto">
                                        <span>{{ scope.row.majorClassName }}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="设备编号" align="center" width="300">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.'+ scope.$index +'.number'" style="margin: auto">
                                        <el-input size="small" v-model="scope.row.number"
                                                  placeholder="若不填系统自动生成" style="width: 200px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="300" label="设备编号">
                                <template slot="header">
                                    <span style="color: red; padding-right: 2px">*</span>
                                    <span>DTU编号</span>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.'+ scope.$index +'.dtuNumber'"
                                                  :rules="qxFormRules.dtuNumber" style="margin: auto">
                                        <el-input size="small" v-model="scope.row.dtuNumber"
                                                  style="width: 200px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-form-item style="margin-bottom: 0px;">
                                        <el-button size="small" type="text"
                                                   @click.native.prevent="deleteQxRow(scope.$index)">删除
                                        </el-button>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>

                    <el-form :model="fzForm" ref="fzForm" :inline="true" :rules="fzFormRules" :inline-message="true"
                             style="margin-top: 20px;display: none">
                        <el-button type="primary" icon="el-icon-plus" size="mini"
                                   @click="addFzRow">辐照仪
                        </el-button>
                        <el-table size="mini" :data="fzForm.tableData" :row-class-name="rowClassName1">
                            <el-table-column label="类型" align="center" width="300">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.'+ scope.$index +'.majorClassName'"
                                                  style="margin: auto">
                                        <span>{{ scope.row.majorClassName }}</span>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="设备编号" align="center" width="300">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.'+ scope.$index +'.number'" style="margin: auto">
                                        <el-input size="small" v-model="scope.row.number"
                                                  placeholder="若不填系统自动生成" style="width: 200px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="300">
                                <template slot="header">
                                    <span style="color: red; padding-right: 2px">*</span>
                                    <span>DTU编号</span>
                                </template>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'tableData.'+ scope.$index +'.dtuNumber'"
                                                  :rules="fzFormRules.dtuNumber" style="margin: auto">
                                        <el-input size="small" v-model="scope.row.dtuNumber"
                                                  style="width: 200px"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-form-item style="margin-bottom: 0px;">
                                        <el-button size="small" type="text"
                                                   @click.native.prevent="deleteFzRow(scope.$index)">删除
                                        </el-button>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>

                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="backStep" v-if="this.active > 0">上一步</el-button>
                <el-button size="mini" type="primary" @click="saveDeviceInfo" v-if="this.active == 4"
                       :loading="addOrEditDeviceLoading">保存</el-button>
                <el-button size="mini" type="primary" @click="nextStep" v-else>下一步</el-button>
                <el-button size="mini" @click="addOrEditDeviceFormVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {queryDeviceTypeList, queryDeviceInfoById, queryOrgInfo, queryDevicePartsInfoById} from "../../../api/station/deviceInfoApi";
import axios from 'axios';
import global from '../../../components/Global';
import {queryStationInfo} from "../../../api/station/stationInfoApi";
import {getToken} from "../../../api/auth";

let that;
export default {
    name: "DeviceParstInfo",
    data() {

        return {
            loadList: [],
            deviceWholeId: '',
            // 组织数据
            orgOption: [],
            // 电站信息
            stationOption: [],
            // 设备基础信息
            deviceForm: {},
            deviceFormRules: {
                orgId: [
                    {required: true, message: '请选所属机构', trigger: ['blur', 'change']}
                ],
                stationId: [
                    {required: true, message: '请选所属电站', trigger: ['blur', 'change']}
                ],
                deviceNum: [
                    {required: true, message: '请填写设备编号', trigger: 'blur'}
                ]
            },
            // 电池信息
            batForm: {
                batteryTableData: [],
            },
            batFormRules: {
                serialNumber: [
                    {required: true, message: '请输入组号', trigger: 'blur'}
                ]
            },
            // 直流电源变换器信息
            dcForm: {
                tableData1: [],
                tableData2: []
            },
            dcFormRules: {
                subCount: [
                    {required: true, message: "组件数量必填", trigger: 'blur'}
                ],
                subUnit: [
                    {required: true, message: "组件规格必填", trigger: 'blur'},
                ]
            },
            // 气象仪信息
            qxForm: {

                tableData: []
            },
            qxFormRules: {
                serialNumber: [
                    {message: '请输入组号', trigger: 'blur'}
                ],
                dtuNumber: [
                    {message: "DTU编号必填", trigger: 'blur'},
                    {pattern: /^\d{12}$/, message: '请输入12位数字的DTU编号', trigger: 'blur'}
                ]
            },
            // 辐照仪信息
            fzForm: {

                tableData: []
            },
            fzFormRules: {
                serialNumber: [
                    {message: '请输入组号', trigger: 'blur'}
                ],
                dtuNumber: [
                    {message: "DTU编号必填", trigger: 'blur'},
                    {pattern: /^\d{12}$/, message: '请输入12位数字的DTU编号', trigger: 'blur'}
                ]
            },
            curDeviceOption: '',
            addOrEditNumberStyle: false,
            addOrEditDeviceDialogTitle: '',
            addOrEditDeviceFormVisible: false,
            addOrEditDeviceLoading: false,
            engineTableData: [],
            environmentTableData: [],
            active: 0,
            batDeviceTypeId: '',
            dcDeviceTypeId1: '',
            dcDeviceTypeId2: '',
            wholeDeviceTypeId: '',
            qxDeviceTypeId: '',
            fzDeviceTypeId: '',
            delPartsData: [],
            orgId: '',
            // active步骤对应状态
            active0Status: 'process',
            active1Status: 'wait',
            active2Status: 'wait',
            active3Status: 'wait',
            active4Status: 'wait',
        }
    },
    methods: {

        // 初始化添加/编辑设备信息页面
        initAddOrEditPage(id) {

            this.engineTableData = [];
            this.batForm.batteryTableData = [];
            this.dcForm.tableData1 = [];
            this.dcForm.tableData2 = [];
            this.qxForm.tableData = [];
            this.fzForm.tableData = [];
            this.environmentTableData = [];
            this.delPartsData = [];
            this.addOrEditDeviceLoading = false;
            this.deviceWholeId = '';
            this.getOrgList();
            this.getStationList();
            // id空为新增，不空为编辑页面
            if (id == '') {

                // 查询可添加的设备类型，并初始化必要添加的组件
                this.getDeviceTypeList(true);
                this.openAddDevicePage();
            } else {

                // 查询可添加的设备类型
                this.getDeviceTypeList(false);
                this.deviceWholeId = id;
                this.openEditDevicePage();
            }
            this.active = 0;
            this.addOrEditDeviceFormVisible = true;

            // 初始化页面步骤条
            this.active0Status = 'process'
            this.active1Status = 'wait'
            this.active2Status = 'wait'
            this.active3Status = 'wait'
            this.active4Status = 'wait'
        },
        // 打开添加页面
        openAddDevicePage() {

            this.addOrEditNumberStyle = false;
            this.addOrEditDeviceDialogTitle = '新增设备';
            this.curDeviceOption = 'add';
            this.deviceForm = {

                orgId: '',
                stationId: '',
                deviceNum: '',
                deviceStatus: 0,
                load1: '',
                load2: '',
                load3: ''
            };
        },
        // 打开编辑页面
        openEditDevicePage() {

            this.addOrEditNumberStyle = true;
            this.addOrEditDeviceDialogTitle = '编辑设备';
            this.curDeviceOption = 'edit';
            let params = {

                id: this.deviceWholeId
            };
            queryDeviceInfoById(params).then((res) => {

                this.deviceForm = res.data;
                this.addOrEditDeviceFormVisible = true;
            });
            queryDevicePartsInfoById(params).then((res) => {

                let data = res.data;
                for (let i = 0; i < data.length; i++) {

                    let prefix = data[i].prefix;
                    let deviceTypeId = data[i].deviceTypeId
                    if (prefix == "FD" || prefix == "DQ" || prefix == "KZ" || prefix == "CY") {

                        this.engineTableData.push(data[i]);
                    } else if (prefix == "DC") {

                        this.batDeviceTypeId = deviceTypeId;
                        this.batForm.batteryTableData.push(data[i]);
                    } else if (prefix == "GF") {

                        this.dcDeviceTypeId1 = deviceTypeId;
                        this.dcForm.tableData1.push(data[i]);
                    } else if (prefix == "ZL") {

                        this.dcDeviceTypeId2 = deviceTypeId;
                        this.dcForm.tableData2.push(data[i]);
                    } else if (prefix == "DT" || prefix == "GK" || prefix == "CK") {

                        if (prefix == "DT") {

                            data[i].majorClassName = "通讯设备";
                        }
                        this.environmentTableData.push(data[i]);
                    } else if (prefix == "JG") {

                        this.wholeDeviceTypeId = deviceTypeId;
                    } else if (prefix == "QX") {

                        this.qxDeviceTypeId = deviceTypeId;
                        this.qxForm.tableData.push(data[i]);
                    } else if (prefix == "FZ") {

                        this.fzDeviceTypeId = deviceTypeId;
                        this.fzForm.tableData.push(data[i]);
                    }
                }
            });
        },
        // 获取机构列表
        getOrgList() {

            let params = {

                curr: 0,
                size: 0
            };
            queryOrgInfo(params).then((res) => {

                this.orgOption = res.data.list;
            }).catch(err => console.error(err));
        },
        // 机构发生变化后触发事件
        orgSelectChange(org) {

            this.stationOption = [];
            this.orgId = org;
            this.deviceForm.stationId = '';
            this.getStationList();
        },
        // 获取基站列表
        getStationList() {

            let param = {
                orgId: this.orgId
            }
            queryStationInfo(param).then((res) => {

                this.stationOption = res.data.list;
            }).catch(err => err);
        },
        // 获取设备信息列表
        getDeviceTypeList(isInitParts) {

            queryDeviceTypeList().then((res) => {

                for (let i = 0; i < res.list.length; i++) {

                    let prefix = res.list[i].prefix;
                    // 基础信息
                    let info = {
                        majorClassName: res.list[i].majorClassName,
                        number: "",
                        deviceTypeId: res.list[i].id,
                        productModelId: 0
                    }
                    if (prefix == "FD" || prefix == "DQ" || prefix == "KZ") {

                        if (isInitParts) {

                            info.prefix = prefix;
                            this.engineTableData.push(info);
                        }
                    } else if (prefix == "CY") {

                        if (isInitParts) {

                            info.subUnit = '';
                            info.prefix = prefix;
                            this.engineTableData.push(info);
                        }
                    } else if (prefix == "DC") {

                        this.batDeviceTypeId = res.list[i].id;
                        if (isInitParts) {

                            for (let j = 0; j < 10; j++) {

                                let batInfo = {

                                    majorClassName: res.list[i].majorClassName,
                                    number: "",
                                    serialNumber: j + 1,
                                    deviceTypeId: res.list[i].id,
                                    productModelId: 0
                                }
                                this.batForm.batteryTableData.push(batInfo);
                            }
                        }
                    } else if (prefix == "GF") {

                        this.dcDeviceTypeId1 = res.list[i].id;
                        if (isInitParts) {

                            info.subCount = '';
                            info.subUnit = '';
                            this.dcForm.tableData1.push(info);
                        }
                    } else if (prefix == "ZL") {

                        this.dcDeviceTypeId2 = res.list[i].id;
                        if (isInitParts) {

                            this.dcForm.tableData2.push(info)
                        }
                    } else if (prefix == "DT" || prefix == "GK" || prefix == "CK") {

                        if (isInitParts) {

                            this.environmentTableData.push(info);
                        }
                    } else if (prefix == "JG") {

                        this.wholeDeviceTypeId = res.list[i].id;
                    } else if (prefix == "QX") {

                        this.qxDeviceTypeId = res.list[i].id;
                        if (isInitParts) {

                            info.prefix = 'QX';
                            info.productModelId = 5;
                            this.qxForm.tableData.push(info);
                        }
                    } else if (prefix == "FZ") {

                        this.fzDeviceTypeId = res.list[i].id;
                        if (isInitParts) {

                            info.prefix = 'FZ';
                            info.productModelId = 4;
                            this.fzForm.tableData.push(info);
                        }
                    }
                }
            });
        },
        // 添加电池信息
        addBatteryRow() {

            if (this.batForm.batteryTableData.length > 9) {

                this.$confirm('电池最多只能10个', '新增失败', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                });
                return;
            }
            let data = {
                "majorClassName": '电池包',
                "number": "",
                "serialNumber": '',
                "deviceTypeId": this.batDeviceTypeId
            }
            this.batForm.batteryTableData.push(data);
        },
        // 删除电池信息
        deleteBatteryRow(index, rows) {

            if (this.wholeId != '' && rows[index].id != '') {
                // 编辑页面删除,已经存在的组件后端也删除
                this.delPartsData.push(rows[index].id);
            }
            rows.splice(index, 1);
        },
        // 添加直流电源变换器信息
        addDcRow(type, typeId) {

            let data = {
                majorClassName: type,
                number: "",
                deviceTypeId: typeId,
                productModelId: 0
            }
            if (type == '光伏模块') {

                data.subCount = '';
                data.subUnit = '';
                this.dcForm.tableData1.push(data);
            } else {

                this.dcForm.tableData2.push(data);
            }
        },
        // 删除直流电源变换器信息
        deleteDcRow(index, type) {

            if (type == '光伏模块') {

                if (this.wholeId != '' && this.dcForm.tableData1[index].id != '') {
                    // 编辑页面删除,已经存在的组件后端也删除
                    this.delPartsData.push(this.dcForm.tableData1[index].id);
                }
                this.dcForm.tableData1.splice(index, 1);
            } else {

                if (this.wholeId != '' && this.dcForm.tableData2[index].id != '') {
                    // 编辑页面删除,已经存在的组件后端也删除
                    this.delPartsData.push(this.dcForm.tableData2[index].id);
                }
                this.dcForm.tableData2.splice(index, 1);
            }
        },
        // 添加气象仪信息
        addQxRow() {

            if (this.qxForm.tableData.length === 1) {

                this.$message({message: '最多只能添加一个气象仪设备！', type: 'warning'});
            } else {

                let data = {
                    prefix: 'QX',
                    majorClassName: "气象仪",
                    number: "",
                    deviceTypeId: this.qxDeviceTypeId,
                    productModelId: 5,
                }
                this.qxForm.tableData.push(data);
            }
        },
        // 删除气象仪信息
        deleteQxRow(index) {

            if (this.wholeId != '' && this.qxForm.tableData[index].id != '') {
                // 编辑页面删除,已经存在的组件后端也删除
                this.delPartsData.push(this.qxForm.tableData[index].id);
            }
            this.qxForm.tableData.splice(index, 1);
        },
        // 添加辐照仪信息
        addFzRow() {

            if (this.fzForm.tableData.length === 1) {

                this.$message({message: '最多只能添加一个辐照仪设备！', type: 'warning'});
            } else {

                let data = {
                    prefix: 'FZ',
                    majorClassName: "辐照仪",
                    number: "",
                    deviceTypeId: this.fzDeviceTypeId,
                    productModelId: 4
                }
                this.fzForm.tableData.push(data);
            }
        },
        // 删除辐照仪信息
        deleteFzRow(index) {

            if (this.wholeId != '' && this.fzForm.tableData[index].id != '') {
                // 编辑页面删除,已经存在的组件后端也删除
                this.delPartsData.push(this.fzForm.tableData[index].id);
            }
            this.fzForm.tableData.splice(index, 1);
        },
        // 上一步
        backStep() {

            this.active = this.active - 1;
            this.stepChange(this.active)
        },
        // 下一步
        nextStep() {

            if (this.active == 0) {

                this.$refs["deviceForm"].validate((valid) => {

                    if (valid) {

                        if (this.deviceForm.load1 == '' && this.deviceForm.load2 == '' && this.deviceForm.load3 == '') {

                            this.$confirm('至少填写一个负载', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            return;
                        }
                        this.active = this.active + 1;
                        this.active0Status = 'success'
                        this.active1Status = 'process'
                    }
                })
            } else if (this.active == 1) {

                let arr = [];
                let flag = true;
                for (let i = 0; i < this.engineTableData.length; i++) {

                    if (this.engineTableData[i].number != '') {

                        if (arr.indexOf(this.engineTableData[i].number) < 0) {

                            arr.push(this.engineTableData[i].number);
                            flag = true;
                        } else {

                            flag = false;
                            this.$confirm('设备编号【' + this.engineTableData[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            break;
                        }
                    }
                    if (this.engineTableData[i].prefix == 'CY') {

                        if (this.engineTableData[i].subUnit == '') {

                            flag = false;
                            this.$confirm('柴油箱组件规格不能为空', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            break;
                        }
                        if (isNaN(this.engineTableData[i].subUnit)) {

                            flag = false;
                            this.$confirm('柴油箱组件规格只能填数字', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            break;
                        }
                    }
                }
                if (flag) {

                    this.active = this.active + 1;
                    this.active1Status = 'success'
                    this.active2Status = 'process'
                }
            } else if (this.active == 2) {

                this.$refs["batForm"].validate((valid) => {

                    if (valid) {

                        let arr = [];
                        let serialArr = [];
                        let flag = true;
                        for (let i = 0; i < this.batForm.batteryTableData.length; i++) {

                            if (this.batForm.batteryTableData[i].serialNumber > 10) {

                                flag = false;
                                this.$confirm('组号不能大于10', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                                break;
                            }
                            if (this.batForm.batteryTableData[i].number != '') {

                                if (arr.indexOf(this.batForm.batteryTableData[i].number) < 0) {

                                    arr.push(this.batForm.batteryTableData[i].number);
                                } else {

                                    flag = false;
                                    this.$confirm('设备编号【' + this.batForm.batteryTableData[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                                    break;
                                }
                            }
                            if (serialArr.indexOf(this.batForm.batteryTableData[i].serialNumber) < 0) {

                                serialArr.push(this.batForm.batteryTableData[i].serialNumber);
                            } else {

                                flag = false;
                                this.$confirm('组号【' + this.batForm.batteryTableData[i].serialNumber + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                                break;
                            }
                        }
                        if (flag) {

                            this.active = this.active + 1;
                            this.active2Status = 'success'
                            this.active3Status = 'process'
                        }
                    }
                })
            } else if (this.active == 3) {

                this.$refs["dcForm"].validate((valid) => {

                    if (valid) {

                        if (this.dcForm.tableData1.length == 0 || this.dcForm.tableData2.length == 0) {

                            this.$confirm('至少有一个光伏模块和一个整流模块', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            return;
                        }
                        if (valid) {

                            let arr = [];
                            let flag = true;
                            for (let i = 0; i < this.dcForm.tableData1.length; i++) {

                                if (this.dcForm.tableData1[i].number != '') {

                                    if (arr.indexOf(this.dcForm.tableData1[i].number) < 0) {

                                        arr.push(this.dcForm.tableData1[i].number);
                                        flag = true;
                                    } else {

                                        flag = false;
                                        this.$confirm('设备编号【' + this.dcForm.tableData1[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                                        break;
                                    }
                                }
                            }
                            if (flag) {

                                for (let i = 0; i < this.dcForm.tableData2.length; i++) {

                                    if (this.dcForm.tableData2[i].number != '') {

                                        if (arr.indexOf(this.dcForm.tableData2[i].number) < 0) {

                                            arr.push(this.dcForm.tableData2[i].number);
                                            flag = true;
                                        } else {

                                            flag = false;
                                            this.$confirm('设备编号【' + this.dcForm.tableData2[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                                            break;
                                        }
                                    }
                                }
                            }
                            if (flag) {

                                this.active = this.active + 1;
                                this.active3Status = 'success'
                                this.active4Status = 'process'
                            }
                        }
                    }
                })
            } else if (this.active == 4) {

                // 校验串口服务器、工控机、通讯设备编号是否重复
                let arr = [];
                let flag = true;
                for (let i = 0; i < this.environmentTableData.length; i++) {

                    if (this.environmentTableData[i].number != '') {

                        if (arr.indexOf(this.environmentTableData[i].number) < 0) {

                            arr.push(this.environmentTableData[i].number);
                            flag = true;
                        } else {

                            flag = false;
                            this.$confirm('设备编号[' + this.environmentTableData[i].number + ']重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            break;
                        }
                    }
                }
                // 校验气象仪设备编号是否重复
                let arr2 = [];
                let qxTableData = this.qxForm.tableData;
                for (let i = 0; i < qxTableData.length; i++) {

                    if (qxTableData[i].number != '') {

                        if (arr2.indexOf(qxTableData[i].number) < 0) {

                            arr2.push(qxTableData[i].number);
                            flag = true;
                        } else {

                            flag = false;
                            this.$confirm('设备编号[' + qxTableData[i].number + ']重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            break;
                        }
                    }
                }
                // 校验辐照仪设备编号是否重复
                let arr3 = [];
                let fzTableData = this.fzForm.tableData;
                for (let i = 0; i < fzTableData.length; i++) {

                    if (qxTableData[i].number != '') {

                        if (arr3.indexOf(fzTableData[i].number) < 0) {

                            arr3.push(fzTableData[i].number);
                            flag = true;
                        } else {

                            flag = false;
                            this.$confirm('设备编号[' + fzTableData[i].number + ']重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            break;
                        }
                    }
                }
                if (flag) {

                    this.active4Status = 'success'
                    // 新增或修改设备信息
                    this.submitSaveDeviceInfo();
                }
            }
        },
        // 组件序号保存至tableData中
        rowClassName1({row, rowIndex}) {

            row.serialNumber = rowIndex + 1;
        },
        rowClassName2({row, rowIndex}) {

            row.serialNumber = this.dcForm.tableData1.length + rowIndex + 1;
        },
        // 步骤发生变化触发事件
        stepChange(value) {

            this.refreshStatus(value)
            switch (value) {

                case 0:
                    this.active0Status = 'process'
                    break;
                case 1:
                    this.active1Status = 'process'
                    break;
                case 2:
                    this.active2Status = 'process'
                    break;
                case 3:
                    this.active3Status = 'process'
                    break;
                case 4:
                    this.active4Status = 'process'
                    break;
                default:
                    break;
            }
            this.active = value
        },
        refreshStatus(targetActive) {

            if (targetActive == 0) {

                this.active1Status = 'wait'
                this.active2Status = 'wait'
                this.active3Status = 'wait'
                this.active4Status = 'wait'
                return;
            }
            let flag = true;
            // 校验基本信息
            this.$refs["deviceForm"].validate((valid) => {

                if (valid) {

                    if (this.deviceForm.load1 == '' && this.deviceForm.load2 == '' && this.deviceForm.load3 == '') {

                        flag = false
                    }
                } else {

                    flag = false
                }
            })
            this.active0Status = flag ? 'success' : 'error'

            if (targetActive == 1) {

                this.active2Status = 'wait'
                this.active3Status = 'wait'
                this.active4Status = 'wait'
                return;
            }

            flag = true;
            let arr = [];
            for (let i = 0; i < this.engineTableData.length; i++) {

                if (this.engineTableData[i].number != '') {

                    if (arr.indexOf(this.engineTableData[i].number) < 0) {

                        arr.push(this.engineTableData[i].number);
                        flag = true;
                    } else {
                        flag = false;
                        break;
                    }
                }
                if (this.engineTableData[i].prefix == 'CY') {

                    if (this.engineTableData[i].subUnit == '') {

                        flag = false;
                        break;
                    }
                    if (isNaN(this.engineTableData[i].subUnit)) {

                        flag = false;
                        break;
                    }
                }
            }
            this.active1Status = flag ? 'success' : 'error'

            if (targetActive == 2) {

                this.active3Status = 'wait'
                this.active4Status = 'wait'
                return;
            }

            flag = true;
            this.$refs["batForm"].validate((valid) => {

                if (valid) {

                    let arr = [];
                    let serialArr = [];
                    for (let i = 0; i < this.batForm.batteryTableData.length; i++) {

                        if (this.batForm.batteryTableData[i].serialNumber > 10) {

                            flag = false;
                            break;
                        }
                        if (this.batForm.batteryTableData[i].number != '') {

                            if (arr.indexOf(this.batForm.batteryTableData[i].number) < 0) {

                                arr.push(this.batForm.batteryTableData[i].number);
                            } else {

                                flag = false;
                                break;
                            }
                        }
                        if (serialArr.indexOf(this.batForm.batteryTableData[i].serialNumber) < 0) {

                            serialArr.push(this.batForm.batteryTableData[i].serialNumber);
                        } else {

                            flag = false;
                            break;
                        }
                    }
                } else {

                    flag = false
                }
            })
            this.active2Status = flag ? 'success' : 'error'

            if (targetActive == 3) {

                this.active4Status = 'wait'
                return;
            }

            flag = true;
            this.$refs["dcForm"].validate((valid) => {

                if (valid) {

                    if (this.dcForm.tableData1.length == 0 || this.dcForm.tableData2.length == 0) {

                        flag = false
                        return
                    }
                    if (valid) {

                        let arr = [];
                        for (let i = 0; i < this.dcForm.tableData1.length; i++) {

                            if (this.dcForm.tableData1[i].number != '') {

                                if (arr.indexOf(this.dcForm.tableData1[i].number) < 0) {

                                    arr.push(this.dcForm.tableData1[i].number);
                                    flag = true;
                                } else {

                                    flag = false;
                                    break;
                                }
                            }
                        }
                        if (flag) {

                            for (let i = 0; i < this.dcForm.tableData2.length; i++) {

                                if (this.dcForm.tableData2[i].number != '') {

                                    if (arr.indexOf(this.dcForm.tableData2[i].number) < 0) {

                                        arr.push(this.dcForm.tableData2[i].number);
                                        flag = true;
                                    } else {

                                        flag = false;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                } else {

                    flag = false
                }
            })
            this.active3Status = flag ? 'success' : 'error'

            if (targetActive == 4) {

                return;
            }

            flag = true;
            let arr1 = [];
            for (let i = 0; i < this.environmentTableData.length; i++) {

                if (this.environmentTableData[i].number != '') {

                    if (arr1.indexOf(this.environmentTableData[i].number) < 0) {

                        arr1.push(this.environmentTableData[i].number);
                        flag = true;
                    } else {
                        flag = false;
                        break;
                    }
                }
            }
            // 校验气象仪设备编号是否重复
            let arr2 = [];
            let qxTableData = this.qxForm.tableData;
            for (let i = 0; i < qxTableData.length; i++) {

                if (qxTableData[i].number != '') {

                    if (arr2.indexOf(qxTableData[i].number) < 0) {

                        arr2.push(qxTableData[i].number);
                        flag = true;
                    } else {

                        flag = false;
                        break;
                    }
                }
            }
            // 校验辐照仪设备编号是否重复
            let arr3 = [];
            let fzTableData = this.fzForm.tableData;
            for (let i = 0; i < fzTableData.length; i++) {

                if (qxTableData[i].number != '') {

                    if (arr3.indexOf(fzTableData[i].number) < 0) {

                        arr3.push(fzTableData[i].number);
                        flag = true;
                    } else {

                        flag = false;
                        break;
                    }
                }
            }
            this.active4Status = flag ? 'success' : 'error'
        },
        // 保存设备信息
        saveDeviceInfo() {

            let flag = true;
            this.$refs["deviceForm"].validate((valid) => {

                if (valid) {

                    if (this.deviceForm.load1 == '' && this.deviceForm.load2 == '' && this.deviceForm.load3 == '') {

                        this.$confirm('至少填写一个负载', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                        });
                        flag = false
                    }
                } else {

                    flag = false
                }
            })
            if (!flag) {

                this.active0Status = 'process'
                this.active1Status = 'wait'
                this.active2Status = 'wait'
                this.active3Status = 'wait'
                this.active4Status = 'wait'
                this.active = 0
                return
            }
            let arr = [];
            for (let i = 0; i < this.engineTableData.length; i++) {

                if (this.engineTableData[i].number != '') {

                    if (arr.indexOf(this.engineTableData[i].number) < 0) {

                        arr.push(this.engineTableData[i].number);
                        flag = true;
                    } else {
                        flag = false;
                        this.$confirm('设备编号【' + this.engineTableData[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                        });
                        break;
                    }
                }
                if (this.engineTableData[i].prefix == 'CY') {

                    if (this.engineTableData[i].subUnit == '') {

                        flag = false;
                        this.$confirm('柴油箱组件规格不能为空', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                        });
                        break;
                    }
                    if (isNaN(this.engineTableData[i].subUnit)) {

                        flag = false;
                        this.$confirm('柴油箱组件规格只能填数字', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                        });
                        break;
                    }
                }
            }
            if (!flag) {

                this.active1Status = 'process'
                this.active2Status = 'wait'
                this.active3Status = 'wait'
                this.active4Status = 'wait'
                this.active = 1
                return
            }

            this.$refs["batForm"].validate((valid) => {

                if (valid) {

                    let arr = [];
                    let serialArr = [];
                    for (let i = 0; i < this.batForm.batteryTableData.length; i++) {

                        if (this.batForm.batteryTableData[i].serialNumber > 10) {
                            flag = false;
                            this.$confirm('组号不能大于10', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                            });
                            break;
                        }
                        if (this.batForm.batteryTableData[i].number != '') {

                            if (arr.indexOf(this.batForm.batteryTableData[i].number) < 0) {

                                arr.push(this.batForm.batteryTableData[i].number);
                            } else {
                                flag = false;
                                this.$confirm('设备编号【' + this.batForm.batteryTableData[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                                });
                                break;
                            }
                        }
                        if (serialArr.indexOf(this.batForm.batteryTableData[i].serialNumber) < 0) {

                            serialArr.push(this.batForm.batteryTableData[i].serialNumber);
                        } else {
                            flag = false;
                            this.$confirm('组号【' + this.batForm.batteryTableData[i].serialNumber + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                            });
                            break;
                        }
                    }
                } else {

                    flag = false;
                }
            })
            if (!flag) {

                this.active2Status = 'process';
                this.active3Status = 'wait';
                this.active4Status = 'wait';
                this.active = 2;
                return;
            }

            this.$refs["dcForm"].validate((valid) => {

                if (valid) {

                    if (this.dcForm.tableData1.length == 0 || this.dcForm.tableData2.length == 0) {

                        this.$confirm('至少有一个光伏模块和一个整流模块', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                        });
                        flag = false
                        return
                    }
                    if (valid) {

                        let arr = [];
                        for (let i = 0; i < this.dcForm.tableData1.length; i++) {

                            if (this.dcForm.tableData1[i].number != '') {

                                if (arr.indexOf(this.dcForm.tableData1[i].number) < 0) {

                                    arr.push(this.dcForm.tableData1[i].number);
                                    flag = true;
                                } else {
                                    flag = false;
                                    this.$confirm('设备编号【' + this.dcForm.tableData1[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                                    });
                                    break;
                                }
                            }
                        }
                        if (flag) {

                            for (let i = 0; i < this.dcForm.tableData2.length; i++) {

                                if (this.dcForm.tableData2[i].number != '') {

                                    if (arr.indexOf(this.dcForm.tableData2[i].number) < 0) {

                                        arr.push(this.dcForm.tableData2[i].number);
                                        flag = true;
                                    } else {
                                        flag = false;
                                        this.$confirm('设备编号【' + this.dcForm.tableData2[i].number + '】重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                                        });
                                        break;
                                    }
                                }
                            }
                        }
                    }
                } else {

                    flag = false;
                }
            })
            if (!flag) {

                this.active3Status = 'process';
                this.active4Status = 'wait';
                this.active = 3;
                return;
            }

            let arr1 = [];
            for (let i = 0; i < this.environmentTableData.length; i++) {

                if (this.environmentTableData[i].number != '') {

                    if (arr1.indexOf(this.environmentTableData[i].number) < 0) {

                        arr1.push(this.environmentTableData[i].number);
                        flag = true;
                    } else {
                        flag = false;
                        this.$confirm('设备编号[' + this.environmentTableData[i].number + ']重复', '提示信息', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                        });
                        break;
                    }
                }
            }
            this.$refs["qxForm"].validate((valid) => {

                if (!valid) {

                    flag = false
                }
            });
            if (!flag) {

                return
            }
            this.$refs["fzForm"].validate((valid) => {

                if (!valid) {

                    flag = false
                }
            });
            if (!flag) {

                return;
            }
            if (flag && this.active == 4) {
                // 新增或修改设备信息
                this.submitSaveDeviceInfo();
            }
        },
        // 添加或修改设备
        submitSaveDeviceInfo() {

            let partsInfos = [];
            partsInfos.push(...this.batForm.batteryTableData);
            partsInfos.push(...this.engineTableData);
            partsInfos.push(...this.dcForm.tableData1);
            partsInfos.push(...this.dcForm.tableData2);
            partsInfos.push(...this.environmentTableData);
            // partsInfos.push(...this.qxForm.tableData);
            // partsInfos.push(...this.fzForm.tableData);
            this.addOrEditDeviceLoading = true;
            let bean = this.deviceForm;
            bean.deviceType = this.wholeDeviceTypeId;
            bean.partsInfoList = partsInfos;
            let url = '';
            if (this.deviceWholeId == '') {

                url = '/deviceInfo/addDeviceInfo';
            } else {

                bean.delPartsIdList = this.delPartsData;
                url = '/deviceInfo/updateWholeDeviceInfo';
            }
            let formData = new FormData();
            formData.append("bean", JSON.stringify(bean));
            let saveRequest = axios.create({
                headers: {

                    "Content-Type": "multipart/form-data",
                    'Authorization': 'Bearer ' + getToken()
                }
            })
            saveRequest({

                url: global.serverBase + url,
                method: 'post',
                data: formData
            }).then(res => {

                this.addOrEditDeviceLoading = false;
                if (res.data.msg === '') {

                    this.addOrEditDeviceFormVisible = false;
                    this.$message({message: '保存成功', type: 'success'});
                    this.$emit('queryDeviceInfo');
                } else {

                    this.$confirm(res.data.msg, '提示', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                }
            }, err => {
                console.log(err)
            })
        }
    },
    mounted() {

        that = this;
    }
}
</script>
<style scoped>
/deep/ .el-form-item__content {
    line-height: 23px !important;
    position: relative;
    font-size: 14px;
}
</style>
